@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');

/* Globals */

:root {
  --focus-outline: #0044FF;
}

* {
  box-sizing: border-box;
  font-family: 'Lato', Arial;
}

body {
  background-color: #1b1b32;
  background-position: center center;
  background-size: cover;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

html,
body,
#root,
main {
  height: 100%;
}

a {
  text-decoration: none;
}

h1,
h2 {
  margin: 0;
}

p {
  margin: 0;
}

/* App */

.App {
  height: 100%;
}

/* Main */

.animation {
  display: none;
}

@media only screen and (min-width: 768px) {
  .animation {
    display: block;
    height: calc(100% - 158px);
    margin-top: 88px;
    position: relative;
    width: 100%;
  }
}

.animation video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.site-title {
  font-size: 30px;
}

@media only screen and (min-width: 768px) {
  .site-title {
    font-size: 18px;
  }
}

.site-description {
  font-size: 18px;
  margin-top: 18px;
}

@media only screen and (min-width: 768px) {
  .site-description {
    margin-left: 10px;
    margin-top: 0;
  }
}

.under-header-content {
  background-color: #0a0a23;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  padding: 0 20px;
  position: absolute;
  text-align: center;
  top: 38px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .under-header-content {
    align-items: center;
    flex-direction: row;
    height: 50px;
    z-index: 1;
  }
}

/* Slider */

.slider-container {
  height: 100%;
  padding: 22px 20px 22px 15px;
  width: 140px;
}

.slider {
  appearance: none;
  -webkit-appearance: none;
  background: #dfdfe2;
  height: 6px;
  width: 100%;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  cursor: pointer;
  height: 15px;
  width: 15px;
}

.slider::-moz-range-thumb {
  background: #fff;
  cursor: pointer;
  height: 15px;
  width: 15px;
}

/* Current Song */

[data-meta='title'] {
  font-size: 18px;
}

[data-meta='artist'] {
  font-size: 14px;
}

[data-meta='album'] {
  font-size: 12px;
}

[data-meta='listeners'] {
  bottom: 20px;
  font-size: 20px;
  font-weight: 900;
  left: 10px;
  position: absolute;
}

@media only screen and (min-width: 768px) {
  [data-meta='listeners'] {
    bottom: auto;
    left: auto;
    top: 2px;
    right: 10px;
  }
}

[data-meta='picture'] {
  bottom: 212px;
  height: 200px;
  left: 50%;
  margin-left: -100px;
  position: absolute;
  width: 200px;
}

@media only screen and (min-width: 768px) {
  [data-meta='picture'] {
    bottom: auto;
    height: 70px;
    left: auto;
    margin-left: 0;
    width: 70px;
  }
}

@media (max-height: 600px) {
  [data-meta='picture'] {
    display: none;
  }
}

.now-playing {
  align-content: center;
  background-color: #1b1b32;
  bottom: 70px;
  display: flex;
  flex-direction: column;
  height: 120px;
  left: 0;
  padding: 10px;
  position: absolute;
  width: 100vw;
}

@media only screen and (min-width: 768px) {
  .now-playing {
    background-color: #0a0a23;
    bottom: auto;
    height: 100%;
    left: 70px;
    padding-bottom: 2px;
    padding-top: 2px;
    position: relative;
    width: calc(100% - 70px);
  }
}

.progress-container {
  border-radius: 4px;
  bottom: 10px;
  height: 4px;
  left: 10px;
  overflow: hidden;
  position: absolute;
  right: 10px;
}

@media only screen and (min-width: 768px) {
  .progress-container {
    bottom: 5px;
  }
}

.now-playing progress {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #3b3b4f;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  border: none;
}

.now-playing progress::-webkit-progress-bar {
  background-color: #3b3b4f;
}

.now-playing progress::-webkit-progress-value {
  background-color: #fff;
}

.meta-display {
  opacity: 0;
  transition: opacity 0.5s ease-out;
  width: calc(100% - 210px);
}

.meta-display-visible {
  opacity: 1;
}

/* Visualizer */

.visualizer {
  background-color: #002ead;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 238px;
  width: 100%;
  z-index: 5;
}

@media only screen and (min-width: 768px) {
  .visualizer {
    background-color: transparent;
    top: 88px;
  }
}

.visualizer canvas {
  height: 100%;
  width: 100%;
}

/* Song History */

.recent-song-history {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 100%;
  padding: 0;
  text-align: center;
  width: 70px;
  z-index: 10;
}

.recent-song-history:hover,
.recent-song-history:active {
  background-color: #1b1b32;
}

.recent-song-history .recently-played-icon {
  color: #fff;
  height: 60%;
  width: 60%;
}

.loader-circle-notch{
  height: 60%;
  width: auto;
  align-self: center;
}

.recent-song-list {
  background-color: #1b1b32;
  bottom: 70px;
  color: #fff;
  cursor: default;
  display: none;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  position: absolute;
  width: 100%;
}

.recent-song-list[aria-hidden="false"] {
  display: flex;
  flex-direction: column-reverse;
}

@media only screen and (min-width: 768px) {
  .recent-song-list {
    bottom: 73px;
    width: 350px;
  }
}

.recently-played-icon {
  font-weight: bold;
  justify-content: center;
}

.recent-song-info {
  border-bottom: 1px solid #3b3b4f;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
}

.recent-song-meta {
  font-size: 16px;
  margin-left: 10px;
  overflow: hidden;
  text-align: left;
}

.recent-song-meta p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.recent-song-meta span:first-of-type {
  font-weight: bold;
}

.recent-song-meta span {
  display: block;
}

.recent-song-info:nth-child(1) {
  border-bottom: none;
}

.recent-song-info img {
  float: left;
  height: 50px;
  width: 50px;
}

/* Details */

details {
  display: none;
}

@media only screen and (min-width: 768px) {
  details {
    background-color: rgba(10, 10, 35, 0.6); /* #0a0a23 */
    bottom: 100px;
    color: #fff;
    display: block;
    opacity: 0.1;
    padding: 15px;
    position: absolute;
    left: 50px;
    transition: opacity 0.25s ease-out;
  }
}

details:hover,
details[open] {
  opacity: 1;
}

dl {
  margin-bottom: 0;
}

dt {
  margin-top: 10px;
}

dd {
  font-style: italic;
  margin-left: 10px;
}

/* Footer */

[data-meta='stream-select'] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: 0.65em auto, 100%;
  border-radius: 0;
  border: none;
  bottom: 90px;
  font-size: 12px;
  padding: 2px 20px 2px 2px;
  position: absolute;
  right: 10px;
}

@media only screen and (min-width: 768px) {
  [data-meta='stream-select'] {
    bottom: 16px;
  }
}

footer {
  background-color: #0a0a23;
  bottom: 0;
  display: flex;
  height: 70px;
  position: absolute;
  width: 100%;
  z-index: 10;
}

@media only screen and (min-width: 768px) {
  footer {
    border-top: 3px solid #fff;
    box-sizing: content-box;
  }
}

/* Play Pause Button */

.play-container {
  background-color: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  height: 100%;
  left: 50%;
  margin-left: -35px;
  padding: 15px;
  position: absolute;
  width: 70px;
}

.play-container:hover,
.play-container:active {
  background-color: #1b1b32;
}

@media only screen and (min-width: 768px) {
  .play-container {
    left: auto;
    margin-left: 0;
    position: relative;
  }
}

.play-container::before {
  background-color: #1b1b32;
  background-image: url(https://cdn-media-1.freecodecamp.org/code-radio/cta.png);
  background-position: 150px center;
  background-repeat: no-repeat;
  border-radius: 5px;
  box-sizing: border-box;
  content: 'Push Play or Space Bar to Start Music';
  display: none;
  font-size: 21px;
  font-weight: bold;
  height: 100px;
  left: -170px;
  opacity: 0;
  padding: 10px 100px 10px 20px;
  pointer-events: none;
  position: absolute;
  top: -110px;
  transition: opacity 0.5s ease-out;
  width: 250px;
}

@media only screen and (min-width: 768px) {
  .play-container::before {
    display: block;
  }
}

.play-container-cta::before {
  opacity: 1;
}

.play-container-cta:hover {
  background: #2a2a40;
}

/* Nav */

.site-nav {
  align-items: flex-start;
  background: #0a0a23;
  display: flex;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  height: 38px;
  justify-content: space-between;
  overflow-y: hidden;
  padding: 0 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

@media only screen and (min-width: 350px) {
  .site-nav {
    padding: 0 15px;
  }
}

.site-nav-middle {
  padding-top: 7px;
}

@media only screen and (min-width: 768px) {
  .site-nav-middle {
    flex: 1 0 30%;
    margin-right: 0;
    text-align: center;
  }
}

.site-nav-left {
  display: none;
}

@media only screen and (min-width: 768px) {
  .site-nav-left {
    display: flex;
    flex: 1 0 30%;
    margin-left: 0;
  }
}

.site-nav-logo {
  background-image: url(https://cdn.freecodecamp.org/platform/universal/fcc_primary.svg);
  background-position: -179px 0;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: inline-block;
  flex-shrink: 0;
  font-size: 1.7rem;
  font-weight: bold;
  height: 25px;
  letter-spacing: -0.5px;
  line-height: 1em;
  margin: -1px 5px 0;
  width: 43px;
}

@media only screen and (min-width: 350px) {
  .site-nav-logo {
    background-image: none;
    height: unset;
    padding-top: 1px;
    width: unset;
  }
}

@media only screen and (min-width: 768px) {
  .site-nav-logo {
    margin-left: auto;
    margin-right: auto;
  }
}

.site-nav-logo:focus {
  outline: 2px solid var(--focus-outline);
  outline-offset: 3px;
}

.site-nav-logo:focus:not(:focus-visible) {
  outline: none;
}

.site-nav-logo:hover {
  text-decoration: none;
}

.site-nav-logo img {
  display: none;
  height: 25px;
  width: auto;
}

@media only screen and (min-width: 350px) {
  .site-nav-logo img {
    display: block;
  }
}

.site-nav-right {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 38px;
}

@media only screen and (min-width: 768px) {
  .site-nav-right {
    flex: 1 0 30%;
    margin-left: auto;
  }
}

@media only screen and (min-width: 768px) {
  .main-nav-group {
    margin-left: auto;
  }
}

.nav {
  background-color: #0a0a23;
  display: none;
  left: 15px;
  list-style: none;
  margin: 0 0 0 -12px;
  padding: 0;
  position: absolute;
  top: 38px;
}

@media only screen and (min-width: 518px) {
  .nav {
    align-items: center;
    display: flex;
    left: auto;
    margin-top: 1px;
    position: relative;
    top: 0;
  }
}

.nav {
  height: 38px;
}

.nav li {
  display: block;
  margin: 0;
  padding: 0;
  height: 100%;
}

.nav li a {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100%;
  margin: 0;
  opacity: 1;
  padding: 0 10px;
  white-space: nowrap;
}

.nav li a span {
  display: flex;
  align-items: center;
  gap: 0.15rem;
  margin-top: -1px;
}

.nav li a svg {
  fill: #fff;
  height: 1rem;
  width: 1rem;
  margin-left: 0.15rem;
}

.nav li a:focus {
  outline: 2px solid var(--focus-outline);
  outline-offset: -3px;
}

.nav li a:focus:not(:focus-visible) {
  outline: none;
}

.nav li:hover {
  background: #fff;
}

.nav li a:hover {
  color: #0a0a23;
  text-decoration: none;
}

.nav li a:hover svg {
  fill: #0a0a23;
}

.toggle-button-nav {
  background-color: #0a0a23;
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: 'lato', sans-serif;
  font-size: 18px;
  height: auto;
  margin-right: 5px;
  outline: 0;
  padding: 2px 14px 3px;
}

.toggle-button-nav:focus {
  outline: 2px solid var(--focus-outline);
  outline-offset: 2px;
}

.toggle-button-nav:focus:not(:focus-visible) {
  outline: none;
}

@media only screen and (min-width: 518px) {
  .toggle-button-nav {
    display: none;
  }
}

.show-main-nav-items {
  display: flex;
}

.expand-nav {
  min-height: calc(40px + 38px);
}

@media only screen and (min-width: 768px) {
  .expand-nav {
    min-height: 38px;
  }
}

.reverse-toggle-color {
  background-color: #fff;
  color: #0a0a23;
}
